import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import App from './_app';
import Article from '../components/Article';

const Template = ({ data }) => (
    <App>
        <Helmet>
            <title>Page Not Found</title>
        </Helmet>
        <Article>
            <h1>Page Not Found</h1>
            <p>The page you requested does not exist.</p>
            <p><Link to="/">Go Home</Link></p>
        </Article>
    </App>
);

export default Template;